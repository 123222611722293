* {
  margin: 0;
  padding: 0;
}

.App {
  text-align: center;
}

/* @media screen and (max-width: 1024px) {
  .testing {
    display: none;
  }
} */

.react-multi-carousel-dot button {
  border-color: #007cfb !important;
  background: white;
}

.react-multi-carousel-dot--active button {
  transform: scale(1.5);
  background: radial-gradient(circle at center, rgba(63, 94, 251, 1) 0%, rgba(63, 94, 251, 1) 50%, rgba(255, 255, 255, 1) 50%, rgba(255, 255, 255, 1) 100%), radial-gradient(circle at center, rgba(63, 94, 251, 1) 0%, rgba(63, 94, 251, 1) 50%, rgba(255, 255, 255, 1) 50%, rgba(255, 255, 255, 1) 100%) !important;
  transition: 0.3s linear !important;
  /* background: #007cfb !important; */
}
.blog-arrow-right > img {
  filter: brightness(0) invert(1);
}

.blog-arrow-left > img {
  filter: brightness(0) invert(1);
}

.slick-arrow-right {
  position: absolute;
  right: -20px;
  display: flex;
  justify-content: center;
  align-items: center;
  top: -90px;
  background-color: #fff;
  border-radius: 62px;
  border: 1px solid #007cfb;
  width: 62px;
  height: 62px;
  flex-shrink: 0;
  cursor: pointer;
}

.slick-arrow-left {
  position: absolute;
  right: 60px;
  width: 62px;
  height: 62px;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  top: -90px;
  background-color: #fff;
  border-radius: 62px;
  border: 1px solid #007cfb;
  cursor: pointer;
}

.blog-arrow-right {
  position: absolute;
  right: -20px;
  display: flex;
  justify-content: center;
  align-items: center;
  top: -100px;
  border-radius: 62px;
  border: 1px solid #494949;
  background: #494949;
  width: 62px;
  height: 62px;
  flex-shrink: 0;
  cursor: pointer;
}

.blog-arrow-left {
  position: absolute;
  right: 60px;
  width: 62px;
  height: 62px;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  top: -100px;
  border-radius: 62px;
  border: 1px solid #494949;
  background: #494949;
  cursor: pointer;
}

@media screen and (max-width: 800px) {
  .blog-arrow-left, .slick-arrow-left {
    top: -140px;
    right: 20px;
    width: 50px;
    height: 50px;
  }

  .blog-arrow-right, .slick-arrow-right {
    top: -70px;
    right: 20px;
    width: 50px;
    height: 50px;
  }
}

@media screen and (max-width: 600px) {
  .slick-arrow-left {
    width: 42px;
    height: 42px;
    bottom: -45px !important;
    right: 70px;
    top: auto;
  }

  .slick-arrow-right {
    width: 42px;
    height: 42px;
    bottom: -45px !important;
    top: auto;
    right: 10px;
  }

  .blog-arrow-left {
    width: 42px;
    height: 42px;
    bottom: -45px !important;
    right: 70px;
    top: auto;
  }

  .blog-arrow-right {
    width: 42px;
    height: 42px;
    bottom: -45px !important;
    top: auto;
    right: 10px;
  }
}


